<template>
  <div class="team-rating">
    <loader v-if="loadingState.match" />
    <template v-else-if="matches.length">
      <MatchesTable :matches="matches" />
      <pvp-pagination
        v-if="showPagination"
        :params="paginationParams"
        :disabled="loadingState.match"
        @onchange="setPage"
      />
    </template>
    <div v-else class="matches-placeholder">
      {{ $t('teams.placeholders_matches') }}
    </div>
  </div>
</template>

<script>
import MatchesTable from '@components/Match/MatchesTable.vue';

export default {
  name: 'TeamMatches',
  components: {
    MatchesTable,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      match: true,
    },
    matches: [],
    // Pagination
    currentPage: 1,
    perPage: 12,
    total: 0,
    perPageSizes: [12, 36, 72],
  }),
  computed: {
    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },
  },

  watch: {
    perPage: {
      handler: 'reloadRating',
    },
    currentPage: {
      handler: 'reloadRating',
      immediate: true,
    },
  },

  methods: {
    ...mapActions('teams', ['fetchTeamMatches']),
    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
    },

    reloadRating() {
      this.loadingState.match = true;
      return this.loadTeamRating();
    },

    loadTeamRating: function () {
      this.fetchTeamMatches({
        page: this.currentPage,
        limit: this.perPage,
        hash: this.hash,
      }).then((data) => {
        this.matches = data.matches;
        this.total = data.pagination.total;
        this.loadingState.match = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 20px;
}

.matches-placeholder {
  margin-top: 20px;
  padding: 24px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background-color: rgba($dark-two, 0.41);
  font-size: 15px;
  text-align: center;
}
</style>
