<template>
  <Table>
    <template #thead>
      <tr>
        <th>
          {{ $t('date.single') }}
        </th>
        <th style="text-align: left">
          {{ $t('tournaments.single') }}
        </th>
        <th>
          {{ $t('matches.single') }}
        </th>
        <th />
      </tr>
    </template>
    <template #tbody>
      <tr v-for="(match, key) in matches" :key="key">
        <td>
          {{ $dt(match.tsStartTime, 'dd_MM_YYYY_HH_mm') }}
        </td>
        <td style="text-align: left">
          <cell-tournament :id="match.tournamentId" />
        </td>
        <td>
          <CellMatch
            :match="match"
            :show-title="false"
            :short-mode="match?.isFfaSystem"
          />
        </td>
        <td>
          <CellMatchLink
            :match="match"
            :tournament-id="match.tournamentId"
          />
        </td>
      </tr>
    </template>
  </Table>
</template>

<script>
import CellTournament from '@components/TableCells/CellTournament.vue';
import CellMatch from '@components/TableCells/CellMatch.vue';
import CellMatchLink from '@components/TableCells/CellMatchLink.vue';
import Table from '@src/components/v2/Table.vue';

export default {
  name: 'MatchesTable',
  components: {
    CellMatchLink,
    CellMatch,
    CellTournament,
    Table,
  },
  props: {
    matches: {
      type: Array,
      required: true,
    },
  },
};
</script>
